import { getAppUrl, getBackUrl, redirectToBackUrl, handleDeliveryMethod, handleAPIEndpoint } from "./services";
import { getDokkaAlias, getLabel } from "labels/hosts";

const amplifyAuthHandler = async({ payload: { event, data } }) => {
  switch (event) {
    case "signIn":
      try {
        const { url, canary, apiUrl } = await getAppUrl();
        const backUrl = getBackUrl();
        handleDeliveryMethod(canary);
        handleAPIEndpoint(apiUrl);
        // in case backUrl exists it has precedence over url returned from getAppUrl();
        if ( backUrl !== null ) {
          redirectToBackUrl();
        } else {
          window.location = url;
        }
      } catch (error) {
        console.error('Sign in event was successful, however we didnt manage to' +
        'identify further url to redirect to. Error details has been loggen before');
      }

      break;
    case "signIn_failure":
    case "cognitoHostedUI_failure":
    case "customState_failure":
      // this indicates SSO error, for other auth type errors we should have code field present
      if (!data.code) {
        try {
          // add alias handling
          let redirect;
          const label = getLabel();
          if (!label.isWL) {
            const { redirectUrl } = getDokkaAlias();
            redirect = redirectUrl;
          } else {
            redirect = label.redirectUrl;
          }
          setTimeout(() => {
            window.location.href = `${redirect}/access_limited?msg=${data.message}`;
          }, 0);
        } catch (_) {
          console.error('Sign in failed,  we didnt manage to' +
          'identify further url to redirect to. Error details has been loggen before');
        }
      }
      break;
    default:
      break;
  }
}

export default amplifyAuthHandler;
