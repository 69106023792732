import { useState, useCallback } from "react";
import Cookies from "js-cookie";

const useCookie = (name, defaultValue, options = {}) => {
  const [value, setValue] = useState(() => {
    const cookie = Cookies.get(name, options);
    if (cookie) return JSON.parse(cookie);
    Cookies.set(name, JSON.stringify(defaultValue), options);
    return defaultValue;
  });

  const updateCookie = useCallback(
    (newValue, options = {}) => {
      Cookies.set(name, JSON.stringify(newValue), options);
      setValue(newValue);
    },
    [name]
  );

  const deleteCookie = useCallback(() => {
    Cookies.remove(name);
    setValue(null);
  }, [name]);

  return [value, updateCookie, deleteCookie];
};

export default useCookie;
