const he = {
  or: "או",
  "auth.login": "התחבר ב-SSO",
  "auth.accept": "אני מאשר",
  "auth.terms": "הסכם שימוש",
  "auth.forgotPassword": "שכחתי סיסמה",
  "auth.privacyPolicy": "מדיניות פרטיות",
  "auth.support": "תמיכה טכנית",
  "auth.cookies": "אנחנו משתמשי בעוגיות כדי לתת לך חוויה טובה יותר",
  "auth.terms.toastErrorMessage": "יש להסכים לתנאי שימוש של השרות",

  "restore.title": "איפוס סיסמה",
  "restore.updatePassword": "עדכון סיסמה",

  "restore.validation.title": "סיסמה חייבת להיות מורכבת מ",
  "restore.validation.minOf": "לפחות",
  "restore.validation.characters": "תווים",
  "restore.validation.lowerCase": "אותיות קטנות",
  "restore.validation.upperCase": "אותיות גדולות",
  "restore.validation.number": "ספרות",
  "restore.validation.symbol": "תווים מיוחדים",

  "resetPassword.expired.title": "תוקף הסיסמה פג",
  "resetPassword.expired.description":
    "יש להגדיר סיסמה חדשה כדי להמשיך להשתמש במערכת",

  "confirmSignIn.timer.title": "אנא היכנס שוב אם לא קיבלת את הקוד",

  "crashPage.somethingWrong": "משהו השתבש",
  "crashPage.backToLogin": "חזרה למסך הכניסה",
  "crashPage.contactAdmin": "אנא פנה למנהל מערכת ההרשאות",

  "forceNewPassword.newPassword.label": "סיסמה חדשה",
  "forceNewPassword.newPassword.placeholder": "הזן סיסמה חדשה",

  // fix default translate
  "Enter your Password": "הכנס את הסיסמה שלך",
  "Enter your email": "הכנס את המייל שלך",
  Code: "קוד בן 6 ספרות",
  "Confirm Password": "אישור סיסמה",
  "Change Password": "הגדרת סיסמה חדשה",
  "Please confirm your Password": "סיסמה חדשה",
  "Code *": "אנא הזן את הקוד שנשלח לכתובת המייל",
  "New Password": "סיסמה חדשה",
  "Confirm SMS Code": "קוד אימות",
  "Incorrect username or password.": "שם משתמש או סיסמה שגוים",
  "Invalid code or auth state for the user.":
    "קוד אימות לא תקין, יש ללחוץ על קישור חזרה למסך הכניסה למערכת",
  "Invalid code provided, please request a code again.":
    "קוד אימות לא תקין, יש ללחוץ על קישור חזרה למסך הכניסה למערכת",
  "Invalid verification code provided, please try again.": "קוד אימות שגוי",
  "Back to Sign In": "חזור למסך הכניסה למערכת",
  "Invalid session for the user, session is expired.":
    "הפעלה שגוייה עבור המשתמש, פג תוקף קוד ההפעלה.",
  "User does not exist.": "משתמש לא קיים",
  "Trial is expired.": "תמה תקופת הנסיון",
  "Organization is not configured.": "הארגון אינו מוגדר כהלכה",
  "Company is inactive.": "החברה אינה פעילה",
  "Your organization has SSO enabled. You cannot login with password":
    "הכניסה למערכת רק תוך שימוש ב-SSO. לא ניתן להכנס עם משתמש וסיסמהץ",
  "Too many invalid credentials attempts. User temporarily locked. Please try again after few seconds.":
    "עקב ריבוי ניסיונות הזדהות כושלים החשבון נחסם לכמה שניות",

  "Network error":
    "cognito-idp.eu-west-1.amazonaws.com is blocked, please check your firewall settings or contact network administrator in your organization",
  "Attempt limit exceeded, please try after some time.":
    "הגישה נחסמה עקב ריבוי ניסיונות כושלים, יש לחזור על הפעולה בקרוב",
  "Temporary password has expired and must be reset by an administrator.":
    "הסיסמה הזמנית לא בתוקף. נא ללחוץ על כפתור ״שכחתי סיסמה״ כדי לשלוח סיסמה זמנית חדשה לכתובת המייל.",
  "Send code": "שלח קוד אימות",
};

export default he;
