import labelsData from "labels";
import domains from "./domains.json";
import aliases from "./aliases.json";

const DEFAULT_LABEL = {
  isWL: false,
  ...aliases.dokka[0],
};

export const getLabel = (config = domains, isAlias = false) =>
  Object.entries(config).reduce(
    (res, [key, hosts]) => {
      let domainConfig;
      const isMatchDomain = Object.entries(hosts).reduce((res, [_, data]) => {
        if (res) {
          return res;
        }

        if (data.loginUrl === window.location.host) {
          domainConfig = data;
          return true;
        }
        return false;
      }, false);

      return isMatchDomain
        ? {
            isWL: !isAlias,
            ...labelsData[isAlias ? "dokka" : key],
            ...domainConfig,
          }
        : res;
    },
    { ...DEFAULT_LABEL, ...labelsData.dokka },
  );

export const getDokkaAlias = () => getLabel(aliases, true);

export const getLabelOptions = () => {
  const { isWL, ...rest } = getLabel();
  return isWL ? { isWL, ...rest } : getDokkaAlias();
};
