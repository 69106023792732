const theme = {
  tokens: {
    colors: {
      error: {
        value: "#D32F2F",
      },
      font: {
        error: { value: "{colors.error}" },
      },
      border: {
        error: { value: "{colors.error}" },
      },
      brand: {
        primary: {
          10: "#0C71E70A",
          20: "#0C71E733",
          40: "#0C71E780",
          60: "#0C71E7CC",
          80: "#0C71E7",
          90: "#084FA1",
          100: "#002B55",
        },
        secondary: {
          10: "#00CE810A",
          20: "#00CE8133",
          40: "#00CE8180",
          60: "#00CE81CC",
          80: "#00CE81",
          90: "#00CE81",
          100: "#00CE81",
        },
      },
    },
    components: {
      authenticator: {
        container: {
          widthMax: {
            value: "36rem",
          },
        },
        router: {
          borderRadius: "16px",
        },
        form: {
          padding: { value: "{space.large}" },
        },
      },
      badge: {
        success: {
          color: { value: "#00905A" },
          backgroundColor: { value: "#00CE8180" },
        },
      },
      button: {
        borderRadius: { value: "20px" },
        color: { value: "{colors.brand.primary[80]}" },
      },
      menu: {
        minWidth: { value: "5rem" },
      },
    },
  },
};

export const getTheme = (brandColors) => {
  if (brandColors) {
    const modifiedTheme = { ...theme };
    modifiedTheme.tokens.colors.brand = brandColors;
    return modifiedTheme;
  }
  return theme;
};
