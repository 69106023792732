import * as React from "react";
import { Auth, I18n } from "aws-amplify";

import {
  Button,
  CheckboxField,
  Divider,
  Flex,
  Link,
  Text,
  useAuthenticator,
  useTheme,
  View,
} from "@aws-amplify/ui-react";

import { useToast } from "contexts/toastContext";
import { useLabelOptions } from "contexts/labelOptionsContext";
import { useAuthContext } from "contexts/authContext";
import { useLocaleState } from "contexts/localeContext";
import { getDokkaAlias } from "labels/hosts";

const SignInFooter = () => {
  const { toResetPassword } = useAuthenticator();
  const { labelOptions } = useLabelOptions();
  const { tokens } = useTheme();
  const { setToastData } = useToast();
  const { setIsResetPasswordFlow } = useAuthContext();
  const { rtl } = useLocaleState();

  const [checked, setChecked] = React.useState(true);
  const [hasError, setHasError] = React.useState(false);

  const handleCheckboxChange = React.useCallback((e) => {
    setChecked(e.target.checked);
    setHasError(false);
  }, []);

  const handleResetPassword = React.useCallback(() => {
    setIsResetPasswordFlow(true);
    toResetPassword();
  }, [toResetPassword, setIsResetPasswordFlow]);

  const handler = React.useCallback(() => {
    setHasError(true);
    setToastData({
      variant: "error",
      message: I18n.get("auth.terms.toastErrorMessage"),
      heading: "",
    });
  }, [setToastData]);

  const fakeCheckbox = document.querySelectorAll('[type="hiddenField"]')[0];

  if (fakeCheckbox) {
    fakeCheckbox.addEventListener("invalid", handler);
  }

  const handleSSOLogin = React.useCallback(() => {
    const { SSOProvider: customProvider } = getDokkaAlias();
    if (checked) {
      Auth.federatedSignIn({ customProvider });
    } else {
      handler();
    }
  }, [handler, checked]);

  React.useEffect(() => {
    if (fakeCheckbox) {
      checked ? (fakeCheckbox.value = checked) : (fakeCheckbox.value = "");
    }
  }, [checked, fakeCheckbox]);

  React.useEffect(
    () => () => {
      setToastData(null);
    },
    [setToastData],
  );
  return (
    <View padding={`0px ${tokens.components.authenticator.form.padding}`}>
      {labelOptions.isSsoLogin && (
        <Flex textAlign="center" direction="column">
          <Divider label={I18n.get("or")} orientation="horizontal" />
          <Button
            fontWeight="normal"
            onClick={handleSSOLogin}
            size="default"
            isFullWidth={true}
            marginTop={tokens.space.xxxs}
          >
            {I18n.get("auth.login")}
          </Button>
        </Flex>
      )}

      <Flex
        justifyContent="space-between"
        paddingTop={tokens.space.xl}
        style={{ flexWrap: "wrap" }}
      >
        <Flex gap="0rem" alignItems="center">
          {labelOptions.termsAndConditionLink && (
            <>
              <CheckboxField
                checked={checked}
                hasError={hasError}
                onChange={handleCheckboxChange}
                label={
                  <Text color={hasError ? tokens.colors.font.error : "inherit"}>
                    {I18n.get("auth.accept")}
                  </Text>
                }
                name="agree"
                value="yes"
              />
              &nbsp;
              <Link href={labelOptions.termsAndConditionLink} isExternal>
                {I18n.get("auth.terms")}
              </Link>
            </>
          )}
        </Flex>
        <Button
          onClick={handleResetPassword}
          variation="link"
          margin={rtl ? "0 auto 0 0" : "0 0 0 auto"}
        >
          {I18n.get("auth.forgotPassword")}
        </Button>
      </Flex>
    </View>
  );
};

export default SignInFooter;
