import { useEffect } from "react";
import { createRoot } from "react-dom/client";

import PasswordValidation from "./components/PasswordValidation";

import "./styles.css";
import { useAuthenticator } from "@aws-amplify/ui-react";

const PASSWORD_VALIDATION_ID = "passwordValidation";

const extraElements = [
  {
    newElementId: PASSWORD_VALIDATION_ID,
    getReferenceElement: () =>
      document.getElementsByClassName("amplify-passwordfield")[0],
    Component: <PasswordValidation />,
  },
];

const PasswordValidationContainer = () => {
  const { isPending } = useAuthenticator();

  useEffect(() => {
    extraElements.forEach(addNewElement);
  }, [isPending]);
  return <div />;
};

export default PasswordValidationContainer;

const addNewElement = ({ newElementId, getReferenceElement, Component }) => {
  const newElementContainer = document.getElementById(newElementId);

  const newElement = document.createElement("div");
  newElement.id = newElementId;
  const referenceElement = getReferenceElement();

  const root = createRoot(newElement);
  root.render(Component);

  if (referenceElement && referenceElement.parentNode && !newElementContainer) {
    referenceElement.parentNode.insertBefore(
      newElement,
      referenceElement.nextSibling,
    );
  }
};
