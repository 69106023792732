import React from "react";
import { I18n } from "aws-amplify";

import Header from "./components/Header";
import Footer from "./components/Footer";
import FormHeader from "./components/FormHeader";
import SignInFooter from "./components/SignInFooter";
import ConfirmResetPasswordSubHeader from "./components/ConfirmResetPasswordSubHeader";
import PasswordValidationContainer from "./components/PasswordValidationContainer";
import ConfirmSignInFooter from "./components/ConfirmSignInFooter";
import { useAuthContext } from "contexts/authContext";

import replaceCodeInput from "./helpers/replaceCodeInput";

const components = {
  Header,
  Footer: () => {
    React.useEffect(() => {
      replaceCodeInput();
    }, []);
    return <Footer />;
  },

  SignIn: {
    Header() {
      return <FormHeader title={I18n.get("Sign in")} />;
    },

    Footer: SignInFooter,
  },
  ResetPassword: {
    Header() {
      return <FormHeader title={I18n.get("restore.title")} />;
    },
  },
  ConfirmResetPassword: {
    Header() {
      const { isResetPasswordFlow } = useAuthContext();
      return (
        <>
          <FormHeader
            title={I18n.get(
              isResetPasswordFlow ? "restore.title" : "restore.updatePassword",
            )}
          />
          {!isResetPasswordFlow && <ConfirmResetPasswordSubHeader />}
        </>
      );
    },
    Footer: PasswordValidationContainer,
  },
  ForceNewPassword: {
    Footer: PasswordValidationContainer,
  },
  ConfirmSignIn: {
    Header() {
      return <FormHeader title={I18n.get("Confirm SMS Code")} />;
    },
    Footer: ConfirmSignInFooter,
  },
};

export default components;
