import * as React from "react";
import { I18n } from "aws-amplify";

import useCookie from "../hooks/useCookie";

import { translations as defaultTranslations } from "@aws-amplify/ui-react";
import translations from "translations";
import { useLabelOptions } from "./labelOptionsContext";

I18n.putVocabularies(defaultTranslations);
I18n.putVocabularies(translations);

const LocaleContext = React.createContext({});

const reload = () => (window.location.href = "/");
const LocaleProvider = ({ children }) => {
  const { labelOptions } = useLabelOptions();
  const cookieOptions = React.useMemo(
    () => ({
      expires: 365,
      domain: labelOptions.cookieDomain,
    }),
    [labelOptions.cookieDomain],
  );

  const [value, updateCookie] = useCookie(
    "locale",
    {
      locale: "en",
      rtl: false,
    },
    cookieOptions,
  );
  const [needUpdate, setNeedUpdate] = React.useState(false);

  const changeLocale = React.useCallback(
    (data) => {
      updateCookie(data, cookieOptions);
      setNeedUpdate(true);
    },
    [updateCookie, cookieOptions],
  );

  React.useEffect(() => {
    I18n.setLanguage(value.locale);
    if (needUpdate) {
      reload();
    }
  }, [value.locale, needUpdate]);

  return (
    <LocaleContext.Provider value={{ ...value, changeLocale }}>
      {children}
    </LocaleContext.Provider>
  );
};

function useLocaleState() {
  const context = React.useContext(LocaleContext);
  return context;
}

export { LocaleProvider, useLocaleState };
