import { Badge, useTheme, Icon } from "@aws-amplify/ui-react";

const RuleItem = ({ isValid, title }) => {
  const { tokens } = useTheme();
  return (
    <Badge
      variation={isValid ? "success" : "default"}
      display="flex"
      style={{ gap: tokens.space.xxs }}
      fontSize={tokens.fontSizes.xs}
    >
      <Icon
        viewBox={{ width: 14, height: 11 }}
        paths={[
          {
            d: "M4.59625 8.28266L1.46875 5.15516L0.403751 6.21266L4.59625 10.4052L13.5963 1.40516L12.5388 0.347656L4.59625 8.28266Z",
            fill: isValid ? tokens.components.badge.success.color : "#86848D",
          },
        ]}
      />
      {title}
    </Badge>
  );
};

export default RuleItem;
