import { createUseStyles } from "react-jss";

export default createUseStyles(() => ({
  asideCarousel: {
    backgroundColor: ({ tokens }) => tokens.colors.white,
    maxWidth: 650,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    direction: "ltr",
    "& .carousel": {
      width: "80% !important",
      display: "flex",
      flexDirection: "column-reverse",
      "& .control-dots": {
        position: "initial",

        "& .dot": {
          backgroundColor: ({ tokens }) => tokens.colors.brand.primary[80],
          width: 6,
          height: 6,
          boxShadow: "none",
          margin: [0, 2],
        },
        "& .dot.selected": {
          width: 14,
          borderRadius: 28,
        },
      },
      "& .slide": {
        "& .legend": {
          color: "#000",
          position: "initial",
          padding: 0,
          background: "none",
          width: "100%",
          marginLeft: 0,
          opacity: 1,
        },
      },
    },
  },
}));
