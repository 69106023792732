import fi from "./images/favicon.ico";
import fiAt from "./images/apple-touch-icon.png";
import fi16 from "./images/favicon-16x16.png";
import fi32 from "./images/favicon-32x32.png";
import logo from "./images/logo.svg";
import extLogo from "./images/extLogo.svg";
import whiteLogo from "./images/whiteLogo.svg";
import name from "./images/name.svg";
import bg from "./images/bg.svg";

const bdoLabelOptions = {
  brand: {
    primary: {
      10: "#5252520A",
      20: "#52525233",
      40: "#52525280",
      60: "#525252CC",
      80: "#525252",
      90: "#393939",
      100: "#393939",
    },
    secondary: {
      10: "#218F8B0A",
      20: "#218F8B33",
      40: "#218F8B80",
      60: "#218F8BCC",
      80: "#218F8B",
      90: "#218F8B",
      100: "#218F8B",
    },
  },
  name: "BDO",
  altName: "BDO",
  site: "https://www.bdo.co.il",
  supportEmail: "support@docs.bdo.co.il",
  termsAndConditionLink:
    "https://www.bdo.co.il/he-il/מדיניות-פרטיות-ותנאים-משפטיים",
  privacyLink: "https://www.bdo.co.il/he-il/מדיניות-פרטיות-ותנאים-משפטיים",
  helpdeskLink:
    "https://dokka-ai.zendesk.com/hc/en-us/categories/360001129852-General",
  onesignalAppId: "098d08c5-a447-455a-bc98-6befb92a22d6",
  fi,
  fiAt,
  fi16,
  fi32,
  title: "BDO",
  theme: "bdo",
  isSsoLogin: false,
  logo: {
    src: logo,
    alt: "BDO logo",
  },
  extLogo: {
    src: extLogo,
    alt: "BDO logo",
  },
  whiteLogo: {
    src: whiteLogo,
    alt: "BDO logo",
  },
  loginNameImg: {
    src: name,
    alt: "BDO",
  },
  backgroundImg: {
    src: bg,
    alt: "BDO background",
  },
  loginSlogan: "ACCOUNTING Automation",
  loginShowErp: false,
  loginLangSelect: true,
  loginPoweredBy: true,
  appStoreDownloadLink: null,
  playMarketDownloadLink: null,
};

export default bdoLabelOptions;
