const fr = {
  or: "OR",
  "auth.login": "Connectez-vous avec SSO",
  "auth.accept": "I agree to the",
  "auth.terms": "Terms of Use",
  "auth.forgotPassword": "Mot de passe oublié",
  "auth.privacyPolicy": "Politique de confidentialité",
  "auth.support": "Support",
  "auth.cookies": "We use cookies to give you a better experience",
  "auth.terms.toastErrorMessage": "Please agree to the terms and conditions",

  "restore.title": "Réinitialiser mot de passe",
  "restore.updatePassword": "Mettre à jour le mot de passe",

  "restore.validation.title": "Your password must contain at least",
  "restore.validation.minOf": "Min of",
  "restore.validation.characters": "characters",
  "restore.validation.lowerCase": "Lower case letter",
  "restore.validation.upperCase": "Upper case letter",
  "restore.validation.number": "Numeric character",
  "restore.validation.symbol": "Special character",

  "resetPassword.expired.title": "Password has expired",
  "resetPassword.expired.description":
    "Set new password to continue use application",

  "confirmSignIn.timer.title":
    "Please sign in again if you didn't receive the code.",

  "crashPage.somethingWrong": "Something went wrong",
  "crashPage.backToLogin": "Back to login",
  "crashPage.contactAdmin": "Please contact your system administrator",

  "forceNewPassword.newPassword.label": "New password",
  "forceNewPassword.newPassword.placeholder": "Type new password",

  // fix default translate
  "Enter your Password": "Saisissez votre mot de passe",
  "Enter your email": "Saisissez votre adresse email",
  Code: "code à 6 chiffres",
  "Confirm Password": "Confirm password",
  "Change Password": "Set new password",
  "Please confirm your Password": "Confirm new password",
  "Code *": "Please enter the verification code we sent to your email",
  "Confirm SMS Code": "Code de vérification",
  "Incorrect username or password.": "Invalid username or password",
  "Invalid code or auth state for the user.":
    "Invalid code submitted. Please click 'Back to Sign' to request a new code",
  "Invalid code provided, please request a code again.":
    "Invalid code submitted. Please click 'Back to Sign' to request a new code",
  "Invalid verification code provided, please try again.":
    "Code de vérification est incorrect.",
  "Back to Sign In": "Back to sign-in",
  "Invalid session for the user, session is expired.":
    "Invalid session for the user, the session has expired.",
  "User does not exist.": "The user does not exist.",
  "Trial is expired.": "The trial has expired.",
  "Organization is not configured.": "The organization is not configured.",
  "Company is inactive.": "The company is inactive.",
  "Your organization has SSO enabled. You cannot login with password":
    "Your organization has Single Sign-On (SSO) enabled. You cannot log in with a password.",
  "Too many invalid credentials attempts. User temporarily locked. Please try again after few seconds.":
    "Too many invalid attempts detected. Your account has been temporarily locked for security reasons. Please try again in few seconds.",

  "Network error":
    "cognito-idp.eu-west-1.amazonaws.com is blocked, please check your firewall settings or contact network administrator in your organization",
  "Temporary password has expired and must be reset by an administrator.":
    'Your temporary password has expired. Click on "Forgot my password" to send a new one.',
  "Send code": "Send verification code",
};

export default fr;
