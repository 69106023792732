import { useMemo } from "react";
import {
  Divider,
  Flex,
  Image,
  useTheme,
  Text,
  Button,
  ThemeProvider,
} from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify";

import Footer from "components/Authenticator/components/Footer";
import { useLabelOptions } from "contexts/labelOptionsContext";
import { useLocaleState } from "contexts/localeContext";
import { getTheme } from "theme";

import SWW from "./images/SWW.svg";

const CrashPage = () => {
  const { tokens } = useTheme();
  const { labelOptions } = useLabelOptions();
  const { rtl } = useLocaleState();
  const theme = useMemo(() => getTheme(labelOptions.brand), [labelOptions]);

  return (
    <ThemeProvider theme={theme} direction={rtl ? "rtl" : "ltr"}>
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="space-between"
        height="100vh"
      >
        <Divider height={8} backgroundColor="#C62828" />
        <Flex direction="column" alignItems="center">
          <Image alt="something went wrong" src={SWW} />
          <Text
            color={theme.tokens.colors.error.value}
            fontSize={tokens.fontSizes.xl}
          >
            {I18n.get("crashPage.somethingWrong")}
          </Text>
          <Text fontSize={tokens.fontSizes.medium}>
            {I18n.get("crashPage.contactAdmin")}
          </Text>
          <Button
            variation="primary"
            onClick={() => (window.location.href = "/")}
          >
            {I18n.get("crashPage.backToLogin")}
          </Button>
        </Flex>
        <Footer style={{ position: "initial" }} />
      </Flex>
    </ThemeProvider>
  );
};

export default CrashPage;
