import { useCallback, useEffect, useState } from "react";

const regExps = {
  regExpLowerCase: /(?=.*[a-z])/,
  regExpUpperCase: /^(?=.*[A-Z])/,
  regExpNumber: /^(?=.*[0-9])/,
  regExpSymbol: /^(?=.*[^A-Za-z0-9])/,
};

export const MIN_CHARACTERS = 8;

const usePasswordValidation = () => {
  const [passwordValue, setPasswordValue] = useState("");
  const [confirmPasswordValue, setConfirmPasswordValue] = useState("");
  const passwordInput = document.querySelectorAll('[name="password"]')[0];
  const confirmPasswordInput = document.querySelectorAll(
    '[name="confirm_password"]',
  )[0];
  const submitButton = document.querySelectorAll('[type="submit"]')[0];

  const handlePasswordChange = useCallback(() => {
    setPasswordValue(passwordInput.value);
  }, [passwordInput]);

  const handleConfirmPasswordChange = useCallback(() => {
    setConfirmPasswordValue(confirmPasswordInput.value);
  }, [confirmPasswordInput]);

  const [validationRules, setValidationRules] = useState({
    minLength: false,
    lowerCase: false,
    upperCase: false,
    number: false,
    symbol: false,
    same: false,
  });

  const isValid = Object.values(validationRules).every(Boolean);
  useEffect(() => {
    if (submitButton) {
      submitButton.classList[isValid ? "remove" : "add"](
        "amplify-button--disabled",
      );
      submitButton[isValid ? "removeAttribute" : "setAttribute"](
        "disabled",
        true,
      );
    }
  }, [isValid, submitButton]);

  useEffect(() => {
    setValidationRules({
      minLength: passwordValue.split("").length > MIN_CHARACTERS - 1,
      lowerCase: regExps.regExpLowerCase.test(passwordValue),
      upperCase: regExps.regExpUpperCase.test(passwordValue),
      number: regExps.regExpNumber.test(passwordValue),
      symbol: regExps.regExpSymbol.test(passwordValue),
      same: passwordValue === confirmPasswordValue,
    });
  }, [passwordValue, confirmPasswordValue]);

  useEffect(() => {
    setPasswordValue(passwordInput?.value);
    setConfirmPasswordValue(confirmPasswordInput?.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (passwordInput) {
      passwordInput.addEventListener("input", handlePasswordChange);
    }
    if (confirmPasswordInput) {
      confirmPasswordInput.addEventListener(
        "input",
        handleConfirmPasswordChange,
      );
    }
    return () => {
      passwordInput.removeEventListener("input", handlePasswordChange);
      confirmPasswordInput.removeEventListener("input", handlePasswordChange);
    };
  }, [
    passwordInput,
    confirmPasswordInput,
    handlePasswordChange,
    handleConfirmPasswordChange,
  ]);

  return { validationRules };
};

export default usePasswordValidation;
