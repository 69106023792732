import { useState } from "react";

const autofillEmail = (params, action = () => {}) => {
  const input = document.querySelectorAll(`[autocomplete='username']`)[0];
  const email = params.get("email");
  if (input && email) {
    // we get email value in UTF-8
    const decodeEmail = decodeURIComponent(email);
    input.value = decodeEmail;
    if (input.value === decodeEmail) {
      action(true);
    }
  }
};
const useRedirect = () => {
  const [isAutofillEmail, setIsAutofillEmail] = useState(false);
  const { pathname, search } = window.location;
  const params = new URLSearchParams(search);

  switch (pathname) {
    case "/resetpassword":
      if (!isAutofillEmail) {
        autofillEmail(params, setIsAutofillEmail);
      }
      return { initialForm: "resetPassword" };
    case "/": {
      if (!isAutofillEmail) {
        autofillEmail(params, setIsAutofillEmail);
      }
      return { initialForm: "signIn" };
    }
    default:
      return { initialForm: "signIn" };
  }
};

export default useRedirect;
