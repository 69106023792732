import * as React from "react";
import { getLabelOptions } from "labels/hosts";

const LabelOptionsContext = React.createContext({});

const LabelOptionsProvider = ({ children }) => {
  const labelOptions = getLabelOptions();

  const updateFavicon = React.useCallback(() => {
    const links = document.getElementsByTagName("link");

    Array.from(links).forEach((link) => {
      const dataID = link.getAttribute("data-icon");
      const option = labelOptions[dataID];
      if (option) {
        link.setAttribute("href", option);
      }
    });
  }, [labelOptions]);

  const updateTitle = React.useCallback(() => {
    const titles = document.getElementsByTagName("title");
    Array.from(titles).forEach((title) => {
      const text = labelOptions.title;
      if (text) {
        title.textContent = text;
      }
    });
  }, [labelOptions]);

  React.useEffect(() => {
    updateFavicon();
    updateTitle();
  }, [updateFavicon, updateTitle]);

  return (
    <LabelOptionsContext.Provider value={{ labelOptions }}>
      {children}
    </LabelOptionsContext.Provider>
  );
};

function useLabelOptions() {
  const context = React.useContext(LabelOptionsContext);
  return context;
}

export { LabelOptionsProvider, useLabelOptions };
