import * as React from "react";

const AuthContext = React.createContext({});

const AuthContextProvider = ({ children }) => {
  const [isResetPasswordFlow, setIsResetPasswordFlow] = React.useState(false);

  return (
    <AuthContext.Provider
      value={{ isResetPasswordFlow, setIsResetPasswordFlow }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuthContext() {
  const context = React.useContext(AuthContext);
  return context;
}

export { AuthContextProvider, useAuthContext };
