const Auth = {
    Auth: {
      // this auth type allows for user migration with Lambda
      authenticationFlowType: 'USER_SRP_AUTH',
      mandatorySignIn: false,
      cookieStorage: {
        domain: process.env.REACT_APP_COOKIE_DOMAIN, // this is overriden
        path: '/',
        expires: 365,
        secure: true,
      },
    },
  };

export default Auth;
