import * as React from "react";

import { Alert, Flex } from "@aws-amplify/ui-react";

import { useToast } from "contexts/toastContext";
import { useLocaleState } from "contexts/localeContext";

const Toasts = () => {
  const { toastData, setToastData } = useToast();
  const { rtl } = useLocaleState();

  const handleDismiss = React.useCallback(() => {
    setToastData(null);
  }, [setToastData]);

  const flexStyles = {
    [rtl ? "left" : "right"]: "0",
    top: 5,
    width: 400,
  };

  return (
    <Flex position="fixed" direction="column" {...flexStyles}>
      {toastData && (
        <Alert
          variation={toastData.variant}
          isDismissible={true}
          hasIcon={true}
          heading={toastData.heading}
          width={395}
          onDismiss={handleDismiss}
        >
          {toastData.message}
        </Alert>
      )}
    </Flex>
  );
};

export default Toasts;
