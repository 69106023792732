const es = {
  or: "O",
  "auth.login": "Iniciar sesión con SSO",
  "auth.accept": "Acepto los",
  "auth.terms": "Términos y condiciones",
  "auth.forgotPassword": "Olvidé mi contraseña",
  "auth.privacyPolicy": "Política de privacidad",
  "auth.support": "Apoyo",
  "auth.cookies": "Usamos cookies para brindarle una mejor experiencia",
  "auth.terms.toastErrorMessage":
    "Por favor acepta los términos y condiciones.",

  "restore.title": "Restablecer la contraseña",
  "restore.updatePassword": "Actualizar contraseña",

  "restore.validation.title": "Su contraseña debe contener al menos",
  "restore.validation.minOf": "Mínimo de",
  "restore.validation.characters": "caracteres",
  "restore.validation.lowerCase": "Letra minúscula",
  "restore.validation.upperCase": "Letra mayúscula",
  "restore.validation.number": "Carácter numérico",
  "restore.validation.symbol": "Personaje especial",

  "resetPassword.expired.title": "La contraseña ha expirado",
  "resetPassword.expired.description":
    "Establezca una nueva contraseña para continuar usando la aplicación",

  "confirmSignIn.timer.title":
    "Inicie sesión nuevamente si no recibió el código.",

  "crashPage.somethingWrong": "Algo salió mal",
  "crashPage.backToLogin": "Volver a iniciar sesión",
  "crashPage.contactAdmin":
    "Por favor póngase en contacto con el administrador de su organización",

  "forceNewPassword.newPassword.label": "Nueva contraseña",
  "forceNewPassword.newPassword.placeholder": "Ingrese una nueva contraseña",

  // fix default translate
  "Enter your email": "Escriba su email",
  Code: "Código de 6 dígitos",
  "Confirm Password": "Confirmar Contraseña",
  "Change Password": "Establecer nueva contraseña",
  "Please confirm your Password": "Confirmar nueva contraseña",
  "Code *":
    "Por favor ingresa el código de verificación que te enviamos a tu correo electrónico",
  "New Password": "Nueva contraseña",
  "Confirm SMS Code": "Código de verificación",
  "Incorrect username or password.": "Usuario o contraseña invalido",
  "Invalid code or auth state for the user.":
    "Código de verificación no válido, haga clic en el enlace para regresar a la pantalla de inicio de sesión del sistema",
  "Invalid code provided, please request a code again.":
    "Código de verificación no válido, haga clic en el enlace para regresar a la pantalla de inicio de sesión del sistema",
  "Invalid verification code provided, please try again.":
    "El código de verificación es incorrecto.",
  "Back to Sign In": "Volver a la pantalla de inicio de sesión",
  "Invalid session for the user, session is expired.":
    "Sesión no válida para el usuario, la sesión ha caducado.",
  "User does not exist.": "El usuario no existe.",
  "Trial is expired.": "El período de prueba ha expirado.",
  "Organization is not configured.": "La organización no está configurada.",
  "Company is inactive.": "La empresa está inactiva.",
  "Your organization has SSO enabled. You cannot login with password":
    "Su organización tiene habilitado el inicio de sesión único (SSO). No puede iniciar sesión con contraseña.",
  "Too many invalid credentials attempts. User temporarily locked. Please try again after few seconds.":
    "Debido a múltiples intentos fallidos de autenticación, la cuenta fue bloqueada por unos segundos.",

  "Network error":
    "cognito-idp.eu-west-1.amazonaws.com is blocked, please check your firewall settings or contact network administrator in your organization",
  "Attempt limit exceeded, please try after some time.":
    "Se superó el límite de intentos, por favor tres después de siete veces.",
  "Temporary password has expired and must be reset by an administrator.":
    'Su contraseña temporal ha caducado. Haga clic en "Olvidé mi contraseña" para enviar una nueva contraseña.',
  "Send code": "Enviar código de verificación",
};

export default es;
