import { Flex, Label, useTheme } from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify";

import RuleItem from "../RuleItem";

import usePasswordValidation, { MIN_CHARACTERS } from "./usePasswordValidation";

const PasswordValidation = () => {
  const { tokens } = useTheme();
  const { validationRules } = usePasswordValidation();

  return (
    <>
      <Label>{I18n.get("restore.validation.title")}:</Label>
      <Flex
        fontSize={14}
        justifyContent="flex-start"
        alignItems="center"
        gap={6}
        wrap="wrap"
        marginTop={tokens.space.xxs}
      >
        <RuleItem
          isValid={validationRules.minLength}
          title={`${I18n.get(
            "restore.validation.minOf",
          )} ${MIN_CHARACTERS} ${I18n.get("restore.validation.characters")}`}
        />
        <RuleItem
          isValid={validationRules.lowerCase}
          title={I18n.get("restore.validation.lowerCase")}
        />
        <RuleItem
          isValid={validationRules.upperCase}
          title={I18n.get("restore.validation.upperCase")}
        />
        <RuleItem
          isValid={validationRules.number}
          title={I18n.get("restore.validation.number")}
        />
        <RuleItem
          isValid={validationRules.symbol}
          title={`${I18n.get("restore.validation.symbol")}: !@#$%^&*()`}
        />
      </Flex>
    </>
  );
};

export default PasswordValidation;
