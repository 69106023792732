import logoUS from "./images/US.svg";
import logoGB from "./images/GB.svg";
import logoIL from "./images/IL.svg";
import logoSP from "./images/SP.svg";
import logoIT from "./images/IT.svg";
import logoFR from "./images/FR.svg";

const localeOptions = [
  {
    value: "en",
    label: "US",
    icon: logoUS,
  },
  {
    value: "uk",
    label: "UK",
    icon: logoGB,
  },
  {
    value: "he",
    label: "עב",
    icon: logoIL,
  },
  {
    value: "es",
    label: "ES",
    icon: logoSP,
  },
  {
    value: "it",
    label: "IT",
    icon: logoIT,
  },
  {
    value: "fr",
    label: "FR",
    icon: logoFR,
  },
];

export default localeOptions;
