import { createUseStyles } from "react-jss";

import mobileCurveMask from "./images/mobile-curve-mask.svg";

export default createUseStyles(() => ({
  asideWLContainer: {
    display: "flex",
    backgroundSize: "contain",
    backgroundPosition: "bottom right",
    backgroundRepeat: "no-repeat",
    justifyContent: "center",
  },
  asideWL: {
    display: "flex",
    flexDirection: "column",
  },
  "@media (max-width: 991px)": {
    asideWLContainer: {
      content: "",
      maskImage: `url(${mobileCurveMask})`,
      maskSize: "cover",
      maskPosition: "left bottom",
      maskRepeat: "no-repeat",
      backgroundImage: ({ tokens }) =>
        `linear-gradient(90deg, ${tokens.colors.brand.primary[80]} 0%, ${tokens.colors.brand.secondary[80]} 100%) !important`,
    },
  },
}));
