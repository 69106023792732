import * as React from "react";

const ToastContext = React.createContext({});

const ToastProvider = ({ children }) => {
  const [toastData, setToastData] = React.useState(null);

  return (
    <ToastContext.Provider value={{ toastData, setToastData }}>
      {children}
    </ToastContext.Provider>
  );
};

function useToast() {
  const context = React.useContext(ToastContext);
  return context;
}

export { ToastProvider, useToast };
