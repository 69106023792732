import { Auth } from "aws-amplify";
import { preForgotPasswordHook } from 'amplify/services';

// Idea behind this hook is to allow API know user is trying to reset password
// In case user hasn't ever changed initial temp password (or this password has expired),
// by default he is not able to resolve issue on his own by using change password and is
// expected to involve administrator for resoution.
// Instead we hook into forgot password process and in case we see user hasn't changed
// initial password yet, API changes his state so that it allows user to invoke change
// password

// In case user is initally ok, prehook does nothing
const services = {
  async handleForgotPassword(email) {
    return preForgotPasswordHook(email)
      .finally(() => Auth.forgotPassword(email));
  }
};

export default services;
