import { useCallback, useEffect, useRef } from "react";
import { getLabelOptions } from "labels/hosts";

const useHealthCheck = () => {
  const isInvoked = useRef(false);

  const invokeHealthCheckApi = useCallback(async () => {
    isInvoked.current = true;
    try {
      fetch(`${process.env.REACT_APP_API_BASEURL}/api/v2/3/healthCheck`, {
        method: "GET",
      })
        .then((response) => {
          if (response.status === 427) {
            const { redirectUrl } = getLabelOptions();
            window.location.href = `${redirectUrl}/maintenance`;
          }
          return response.json();
        })
        .then((response) => response)
        .catch((err) => console.error(err));
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (!isInvoked.current) {
      invokeHealthCheckApi().then();
    }
  }, [invokeHealthCheckApi]);
};

export default useHealthCheck;
