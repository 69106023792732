import { Message } from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify";

const ConfirmResetPasswordSubHeader = () => (
  <Message
    colorTheme="warning"
    heading={I18n.get("resetPassword.expired.title")}
  >
    {I18n.get("resetPassword.expired.description")}
  </Message>
);

export default ConfirmResetPasswordSubHeader;
