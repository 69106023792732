import { Flex, Text, useTheme, View, Link } from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify";

import { useLabelOptions } from "contexts/labelOptionsContext";

const Footer = ({ style = {} }) => {
  const { tokens } = useTheme();
  const { labelOptions } = useLabelOptions();

  return (
    <View
      textAlign="center"
      padding={tokens.space.large}
      fontSize={tokens.fontSizes.xs}
      width={tokens.components.authenticator.container.widthMax}
      position="absolute"
      bottom="0"
      className="footer"
      color={tokens.colors.brand.primary[80]}
      {...style}
    >
      <Flex justifyContent="center" gap={tokens.space.xs}>
        <Link href={`mailto:${labelOptions.supportEmail}`} color="inherit">
          {I18n.get("auth.support")}
        </Link>
        {labelOptions.termsAndConditionLink && (
          <>
            <Text color="inherit">•</Text>
            <Link
              href={labelOptions.termsAndConditionLink}
              isExternal
              color="inherit"
            >
              {I18n.get("auth.terms")}
            </Link>
          </>
        )}
        {labelOptions.privacyLink && (
          <>
            <Text color="inherit">•</Text>
            <Link href={labelOptions.privacyLink} isExternal color="inherit">
              {I18n.get("auth.privacyPolicy")}
            </Link>
          </>
        )}
      </Flex>
      <Text>{I18n.get("auth.cookies")}</Text>
    </View>
  );
};

export default Footer;
