import * as React from "react";
import {
  Flex,
  Text,
  useTheme,
  View,
  Image,
  Menu,
  MenuItem,
  MenuButton,
  useAuthenticator,
} from "@aws-amplify/ui-react";

import { useLocaleState } from "contexts/localeContext";

import localeOptions from "./localeOptions";

import useStyles from "./sheet";

const Item = ({ label, icon }) => {
  const { tokens } = useTheme();
  return (
    <Flex gap={tokens.space.xxs}>
      <Image src={icon} alt={label} />
      <Text>{label}</Text>
    </Flex>
  );
};

const Header = () => {
  const { route } = useAuthenticator();
  const { tokens } = useTheme();
  const classes = useStyles({ tokens });

  const { locale, rtl, changeLocale } = useLocaleState();

  const selectedItem =
    localeOptions.filter(({ value }) => value === locale)[0] ||
    localeOptions[0];

  const handleItemClick = React.useCallback(
    (lang) => () => {
      changeLocale({ locale: lang, rtl: lang === "he" });
    },
    [changeLocale],
  );

  const containerStyles = React.useMemo(
    () => ({
      [rtl ? "right" : "left"]: tokens.space.xxxs,
    }),
    [rtl, tokens.space.xxxs],
  );

  return (
    <View
      position="absolute"
      top={tokens.space.xxxs}
      style={containerStyles}
      width="98%"
    >
      {route === "signIn" && (
        <Menu
          menuAlign="center"
          trigger={
            <MenuButton
              variation="default"
              size="small"
              minWidth="80px"
              backgroundColor={tokens.colors.background.tertiary}
              borderRadius={tokens.fontSizes.xxxs}
              display={{ base: "none", large: "block" }}
            >
              <Item label={selectedItem.label} icon={selectedItem.icon} />
            </MenuButton>
          }
        >
          {localeOptions.map((options) => (
            <MenuItem
              key={options.value}
              className={options.value === locale ? classes.active : ""}
              onClick={handleItemClick(options.value)}
              borderRadius="0"
            >
              <Item label={options.label} icon={options.icon} />
            </MenuItem>
          ))}
        </Menu>
      )}
    </View>
  );
};

export default Header;
