import en from "./en";
import es from "./es";
import it from "./it";
import he from "./he";
import fr from "./fr";

const translations = {
  en,
  es,
  fr,
  he,
  it,
  uk: en,
};

export default translations;
