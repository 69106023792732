import { Image, useTheme, View, Text, Link } from "@aws-amplify/ui-react";

import { useLabelOptions } from "contexts/labelOptionsContext";

import useStyles from "./sheet";

const AsideWL = () => {
  const { tokens } = useTheme();
  const {
    labelOptions: {
      headLogo,
      backgroundImg,
      loginNameImg,
      loginSlogan,
      loginPoweredBy,
      asideBgColor,
    },
  } = useLabelOptions();

  const classes = useStyles({ tokens });

  return (
    <View
      width={{
        large: "100%",
        base: "100vw",
      }}
      height={{
        large: "100%",
        base: "100vh",
      }}
      backgroundColor={asideBgColor || tokens.colors.brand.primary[80]}
      backgroundImage={`url("${backgroundImg?.src}")`}
      className={classes.asideWLContainer}
    >
      <View
        className={classes.asideWL}
        display={{ base: "none", large: "flex" }}
      >
        {headLogo && (
          <View width={160} margin={`${tokens.space.medium} auto`}>
            <Image alt={headLogo.alt} src={headLogo.src} width="100%" />
          </View>
        )}
        <View maxWidth={300} margin="auto">
          <Image alt={loginNameImg.alt} src={loginNameImg.src} width="100%" />
          {loginSlogan && (
            <Text
              marginTop={tokens.space.xs}
              color={tokens.colors.white}
              textTransform="uppercase"
              textAlign="center"
            >
              {loginSlogan}
            </Text>
          )}
        </View>
        {loginPoweredBy && (
          <View margin={`0 auto ${tokens.space.xs} auto`}>
            <Link
              href="https://www.dokka.com"
              isExternal
              color={tokens.colors.white}
            >
              Powered by DOKKA
            </Link>
          </View>
        )}
      </View>
    </View>
  );
};

export default AsideWL;
