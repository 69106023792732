import { useEffect, useMemo, useRef, useState } from "react";
import { useTheme, View, Text } from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify";

import "./styles.css";

const SECONDS_IN_MINUTE = 60;

const ConfirmSignInFooter = () => {
  const { tokens } = useTheme();
  const [timer, setTimer] = useState(180);
  const increment = useRef(null);

  useEffect(() => {
    increment.current = setInterval(() => {
      setTimer((timer) => {
        if (timer > 0) {
          return timer - 1;
        } else {
          clearInterval(increment.current);
          return timer;
        }
      });
    }, 1000);
    return () => {
      clearInterval(increment.current);
    };
  }, []);

  const countdownTimer = useMemo(() => {
    const minutes = Math.floor(timer / SECONDS_IN_MINUTE);
    const seconds = timer % SECONDS_IN_MINUTE;
    return `${minutes}:${seconds <= 9 ? `0${seconds}` : seconds}`;
  }, [timer]);

  return (
    <View className="counter" backgroundColor={tokens.colors.brand.primary[20]}>
      <Text>{I18n.get("confirmSignIn.timer.title")}</Text>
      {timer > 0 && (
        <Text color={tokens.colors.brand.primary[80]} fontWeight="bold">
          &nbsp;{countdownTimer}&nbsp;
        </Text>
      )}
    </View>
  );
};

export default ConfirmSignInFooter;
