import fi from "./images/favicon.ico";
import fiAt from "./images/apple-touch-icon.png";
import fi16 from "./images/favicon-16x16.png";
import fi32 from "./images/favicon-32x32.png";
import logo from "./images/logo.svg";
import extLogo from "./images/extLogo.svg";
import whiteLogo from "./images/whiteLogo.svg";
import name from "./images/name.svg";
import bg from "./images/bg.svg";

const profitianLabelOptions = {
  brand: {
    primary: {
      10: "#7CAAC30A",
      20: "#7CAAC333",
      40: "#7CAAC380",
      60: "#7CAAC3CC",
      80: "#7CAAC3",
      90: "#567688",
      100: "#567688",
    },
    secondary: {
      10: "#FFC7290A",
      20: "#FFC72933",
      40: "#FFC72980",
      60: "#FFC729CC",
      80: "#FFC729",
      90: "#FFC729",
      100: "#FFC729",
    },
  },
  name: "Profitian",
  altName: "Profitian Finance",
  site: "https://app.profitian-tech.com",
  supportEmail: "support@profitian-tech.com",
  termsAndConditionLink: "https://www.profitian.co.il/term-of-use",
  privacyLink: "https://www.profitian.co.il/privacy-policy",
  helpdeskLink:
    "https://dokka-ai.zendesk.com/hc/en-us/categories/360001129852-General",
  onesignalAppId: "b39d6b5b-bd3a-45ff-a49c-4aba67344128",
  fi,
  fiAt,
  fi16,
  fi32,
  title: "Profitian Finance",
  theme: "profitian",
  isSsoLogin: false,
  logo: {
    src: logo,
    alt: "Profitian Finance logo",
  },
  extLogo: {
    src: extLogo,
    alt: "Profitian Finance logo",
  },
  whiteLogo: {
    src: whiteLogo,
    alt: "Profitian Finance logo",
  },
  loginNameImg: {
    src: name,
    alt: "Profitian Finance",
  },
  backgroundImg: {
    src: bg,
    alt: "Made 360 background",
  },
  loginSlogan: "Expect More",
  loginShowErp: false,
  loginLangSelect: true,
  loginPoweredBy: true,
  appStoreDownloadLink: null,
  playMarketDownloadLink: null,
  asideBgColor: "#FFC729",
};

export default profitianLabelOptions;
