import fi from "./images/favicon.ico";
import fiAt from "./images/apple-touch-icon.png";
import fi16 from "./images/favicon-16x16.png";
import fi32 from "./images/favicon-32x32.png";
import logo from "./images/logo.svg";
import extLogo from "./images/extLogo.svg";
import whiteLogo from "./images/whiteLogo.svg";
import loginNameImg from "labels/autodok/images/loginNameImg.svg";
import bg from "labels/autodok/images/bg.svg";
import headLogo from "labels/autodok/images/headLogo.svg";

const autodokLabelOptions = {
  brand: {
    primary: {
      10: "#6E6D730A",
      20: "#6E6D7333",
      40: "#6E6D7380",
      60: "#6E6D73CC",
      80: "#6E6D73",
      90: "#4D4C50",
      100: "#4D4C50",
    },
    secondary: {
      10: "#F9C11B0A",
      20: "#F9C11B33",
      40: "#F9C11B80",
      60: "#F9C11BCC",
      80: "#F9C11B",
      90: "#F9C11B",
      100: "#F9C11B",
    },
  },
  name: "AutoDok",
  altName: "AutoDok",
  site: "https://autodok.co.il",
  supportEmail: "support@autodok.co.il",
  termsAndConditionLink: "https://autofont.co.il/autodok_terms-conditions/",
  privacyLink: "https://autofont.co.il/privacy_policy/",
  helpdeskLink: "https://support.dokka.com",
  onesignalAppId: "ff8ba228-8d58-4593-baac-614c3d251695",
  fi,
  fiAt,
  fi16,
  fi32,
  title: "AutoDok",
  isSsoLogin: false,
  theme: "autodok",
  logo: {
    src: logo,
    alt: "Autodok logo",
  },
  extLogo: {
    src: extLogo,
    alt: "Autodok logo",
  },
  whiteLogo: {
    src: whiteLogo,
    alt: "Autodok logo",
  },
  loginNameImg: {
    src: loginNameImg,
    alt: "Autodok",
  },
  backgroundImg: {
    src: bg,
    alt: "Autodok background",
  },
  headLogo: {
    src: headLogo,
    alt: "Autodok head logo",
  },
  // loginSlogan: "ACCOUNTING Automation",
  loginShowErp: true,
  loginLangSelect: true,
  loginPoweredBy: true,
};

export default autodokLabelOptions;
