import fi from "./images/favicon.ico";
import fiAt from "./images/apple-touch-icon.png";
import fi16 from "./images/favicon-16x16.png";
import fi32 from "./images/favicon-32x32.png";
import logo from "./images/logo.svg";
import extLogo from "./images/extLogo.svg";
import whiteLogo from "./images/whiteLogo.svg";
import name from "./images/name.svg";

const alprimeLabelOptions = {
  name: "AlPrime",
  altName: "ALPRIME",
  site: "https://www.alprimecap.com",
  supportEmail: "help@alprimecap.com",
  termsAndConditionLink: "https://www.alprimecap.com/terms-conditions/",
  privacyLink: "https://www.alprimecap.com/privacy-policy/",
  helpdeskLink: "https://www.alprimecap.com/contact/",
  onesignalAppId: "8969b008-98e1-4043-8aff-7f309e0051f9",
  fi,
  fiAt,
  fi16,
  fi32,
  title: "AlPrime Capital",
  isSsoLogin: false,
  theme: "dokka",
  logo: {
    src: logo,
    alt: "AlPrime logo",
  },
  extLogo: {
    src: extLogo,
    alt: "AlPrime logo",
  },
  whiteLogo: {
    src: whiteLogo,
    alt: "AlPrime logo",
  },
  loginNameImg: {
    src: name,
    alt: "ALPRIME",
  },
  loginSlogan: null,
  loginShowErp: false,
  loginLangSelect: false,
  loginPoweredBy: false,
  appStoreDownloadLink:
    "https://apps.apple.com/us/app/alprime-capital/id1501497617?ls=1",
  playMarketDownloadLink:
    "https://play.google.com/store/apps/details?id=com.dokka.alprimecapital",
};

export default alprimeLabelOptions;
