import { Divider, Flex, Heading, Image, useTheme } from "@aws-amplify/ui-react";

import { useLabelOptions } from "contexts/labelOptionsContext";

import DokkaLogo from "../../images/DOKKA.svg";

const FormHeader = ({ title }) => {
  const { tokens } = useTheme();
  const { labelOptions } = useLabelOptions();

  return (
    <Flex direction="column" alignItems="center" gap={tokens.space.large}>
      <Image
        alt={labelOptions.logo.alt}
        src={labelOptions.isWL ? labelOptions.logo.src : DokkaLogo}
        maxWidth={160}
        maxHeight={105}
      />
      <Divider
        borderColor={tokens.colors.brand.primary[80]}
        width={120}
        marginTop={tokens.space.xs}
      />
      <Heading level={3}>{title}</Heading>
    </Flex>
  );
};

export default FormHeader;
