import fi from "./images/favicon.ico";
import fiAt from "./images/apple-touch-icon.png";
import fi16 from "./images/favicon-16x16.png";
import fi32 from "./images/favicon-32x32.png";
import logo from "./images/logo.svg";
import extLogo from "./images/extLogo.svg";
import whiteLogo from "./images/whiteLogo.svg";
import name from "./images/name.svg";

const pegasusLabelOptions = {
  name: "Pegasus",
  altName: "PEGASUS",
  site: "https://www.pegasusconsulting.co.za",
  supportEmail: "help@pegasus.com",
  termsAndConditionLink: "https://www.pegasusconsulting.co.za/terms-conditions",
  privacyLink: "https://www.pegasusconsulting.co.za/privacy-policy",
  helpdeskLink:
    "https://dokka-ai.zendesk.com/hc/en-us/categories/360001129852-General",
  onesignalAppId: "45b7e447-decc-4e76-8446-db102a1c9541",
  fi,
  fiAt,
  fi16,
  fi32,
  title: "Pegasus Сonsulting",
  isSsoLogin: false,
  theme: "dokka",
  logo: {
    src: logo,
    alt: "Pegasus logo",
  },
  extLogo: {
    src: extLogo,
    alt: "Pegasus logo",
  },
  whiteLogo: {
    src: whiteLogo,
    alt: "Pegasus logo",
  },
  loginNameImg: {
    src: name,
    alt: "PEGASUS",
  },
  loginSlogan: null,
  loginShowErp: false,
  loginLangSelect: false,
  loginPoweredBy: true,
  appStoreDownloadLink:
    "https://apps.apple.com/us/app/pegasus-consulting/id1479274534?ls=1",
  playMarketDownloadLink:
    "https://play.google.com/store/apps/details?id=com.dokka.pegasus",
};

export default pegasusLabelOptions;
