import fi from "./images/favicon.ico";
import fiAt from "./images/apple-touch-icon.png";
import fi16 from "./images/favicon-16x16.png";
import fi32 from "./images/favicon-32x32.png";
import logo from "./images/logo.svg";
import extLogo from "./images/extLogo.svg";
import whiteLogo from "./images/whiteLogo.svg";
import name from "./images/name.svg";

const dokkaLabelOptions = {
  name: "Dokka",
  altName: "DOKKA",
  site: "https://www.dokka.com/",
  supportEmail: "support@dokka.com",
  termsAndConditionLink: "https://www.dokka.com/terms-conditions/",
  privacyLink: "https://www.dokka.com/privacy-policy/",
  helpdeskLink:
    "https://dokka-ai.zendesk.com/hc/en-us/categories/360001129852-General",
  onesignalAppId: "55a780ef-ab11-4f29-a1a0-e674ec44d098",
  fi,
  fiAt,
  fi16,
  fi32,
  title: "Dokka A. I.",
  isSsoLogin: true,
  theme: "dokka",
  logo: {
    src: logo,
    alt: "Dokka logo",
  },
  extLogo: {
    src: extLogo,
    alt: "Dokka logo",
  },
  whiteLogo: {
    src: whiteLogo,
    alt: "Dokka logo",
  },
  loginNameImg: {
    src: name,
    alt: "DOKKA",
  },
  loginSlogan: "ACCOUNTING Automation",
  loginShowErp: true,
  loginLangSelect: true,
  loginPoweredBy: false, // TODO: check if used in new design
  appStoreDownloadLink: "https://apps.apple.com/us/app/dokka/id1445350786?ls=1",
  playMarketDownloadLink:
    "https://play.google.com/store/apps/details?id=com.dokka",
};

export default dokkaLabelOptions;
