import { createRoot } from "react-dom/client";
import VerificationInput from "react-verification-input";
import { I18n } from "aws-amplify";

import "./styles.css";

const CODE_INPUT_ID = "CODE_INPUT";

const findElementByTextContent = (rootElement, searchText) => {
  for (let i = 0; i < rootElement.length; i++) {
    const element = rootElement[i];
    if (element.textContent.includes(searchText)) {
      return element;
    }
  }
  return null;
};

const replaceCodeInput = () => {
  const newInputContainer = document.getElementById(CODE_INPUT_ID);

  if (!newInputContainer) {
    const newElement = document.createElement("div");
    newElement.id = CODE_INPUT_ID;
    const referenceElement = document.getElementsByName("confirmation_code")[0];
    if (referenceElement) {
      const inputLabelElements = findElementByTextContent(
        document.getElementsByTagName("label"),
        I18n.get("Code *"),
      );

      const root = createRoot(newElement);
      root.render(
        <VerificationInput
          validChars="0-9"
          placeholder=""
          inputProps={{
            inputMode: "numeric",
            name: "confirmation_code",
            required: true,
          }}
        />,
      );
      if (referenceElement && referenceElement.parentElement) {
        referenceElement.parentElement.replaceChild(
          newElement,
          referenceElement,
        );
      }
      if (inputLabelElements) {
        inputLabelElements.style.textAlign = "center";
        inputLabelElements.style.fontSize = "1rem";
      }
    }
  }
};

export default replaceCodeInput;
